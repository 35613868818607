// work data

export const Work = [
    {
        id:1,
        name:"Cupid:",
        description:"\"I give up. She's doing my job better than me. 🎯❤️\"",
        tags:["react","gsap","styledComponents"],
        
        demo:"https://agency-website-eta.vercel.app/",
        github:"https://github.com/codebucks27/Agency-website"
    },
    {
        id:2,
        name:"Mirror",
        description:"\"I’m exhausted. I've never worked this hard to keep up 🪞😅\""
,
        tags:["react","sass"],
        
        demo:"http://react-job-listing-website.codebucks27.vercel.app/",
        github:"https://github.com/codebucks27/react-job-listing-website"
        
    },
    {
        id:3,
        name:"Sun",
        description:" \"Even I have to admit, she’s a little brighter than me.\"",
        tags:["react","api","sass"],
        
        demo:"http://devto-clone-codebucks27.vercel.app/",
        github:"https://github.com/codebucks27/devto-clone"
    },  
] 